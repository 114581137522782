@import './styles/theme-variables.scss';

:root {
  --chart-background-color: #f8f8fa;
  --chart-background-color-dark: #222736;

  --chart-outline-color: #eff2f7;
  --chart-outline-color-dark: #222736;

  --chart-text-color-primary: #1f2d3d;
  --chart-text-color-primary-dark: #eff2f7;

  --chart-text-color-secondary: #757575;
  --chart-text-color-secondary-dark: #616161;
  --chart-text-color-secondary-light: #929292;
  --chart-text-color-inactive: #bdbdbd;
}

/* Layout wrapper styles */
.layout-wrapper {
  @include themify($themes) {
    background: themed('chartBackgroundColor');
  }

  @include themify($themes) {
    color: themed('chartTextColorPrimary');
  }
}

.toggle-btn__input-label {
  @include themify($themes) {
    background-color: themed('toggleBackground');
  }
}

.controls button,
.controls input {
  @include themify($themes) {
    background: themed('chartBackgroundColor');
  }
  @include themify($themes) {
    color: themed('chartTextColorPrimary');
  }
}

.single-line-legend-wrapper {
  @include themify($themes) {
    border: themed('tooltipBorder');
  }
  @include themify($themes) {
    background: themed('chartBackgroundColorOp');
  }
  @include themify($themes) {
    color: themed('chartTextColorPrimary');
  }
}

svg .nodes-group .node {
  @include themify($themes) {
    stroke: themed('NodeCircleStroke');
  }
}

defs marker {
  fill: white;
  @include themify($themes) {
    stroke: themed('SwitchOpenLineColorStroke');
  }
}

#switch-open line.background {
  @include themify($themes) {
    stroke: themed('SwitchOpenLineBackgroundStroke');
  }
}
#switch-open line.color {
  @include themify($themes) {
    stroke: themed('SwitchOpenLineColorStroke');
  }
}

svg .nodes-group .node-label,
svg .links-group .node-label {
  @include themify($themes) {
    fill: themed('chartTextColorPrimary');
  }
  @include themify($themes) {
    stroke: themed('chartOutlineColor');
  }
}
