$themes: (
  light: (
    chartBackgroundColor: var(--chart-background-color),
    chartBackgroundColorOp: #ffffffc0,
    chartTextColorPrimary: var(--map-text-color-secondary),
    chartOutlineColor: var(--chart-outline-color),
    toggleBackground: #dfe4ea,
    tooltipBorder: solid thin #c5c3db,
    NodeCircleStroke: black,
    SwitchStroke: #7f7f7f,
    SwitchOpenLineBackgroundStroke: white,
    SwitchOpenLineColorStroke: #4e4a4a,
  ),
  dark: (
    chartBackgroundColor: var(--chart-background-color-dark),
    chartBackgroundColorOp: #413d3db4,
    chartTextColorPrimary: var(--chart-text-color-primary-dark),
    chartOutlineColor: var(--chart-outline-color-dark),
    toggleBackground: #606071,
    tooltipBorder: solid thin #575454,
    NodeCircleStroke: white,
    SwitchStroke: #7f7878,
    SwitchOpenLineBackgroundStroke: black,
    SwitchOpenLineColorStroke: #807a7a,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
